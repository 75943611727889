import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Configuration`}</h1>
    <ul>
      <li parentName="ul">{`Settings for each `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` available in the `}<a parentName="li" {...{
          "href": "/admin-interface",
          "title": "admin interface"
        }}>{`admin interface`}</a>{``}</li>
      <li parentName="ul">{`See: `}{`[conversation configuration]`}{`(`}<a parentName="li" {...{
          "href": "/conversation-configuration",
          "title": "conversation configuration"
        }}>{`conversation configuration`}</a>{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      